import { getDomainFromLocation, getEnvironmentDomainPrefix, Types } from '..';

export const getEventRedirectUrl = (
	event: Pick<Types.EventR, 'ubEventId'> & { contentId: string | undefined },
	section?: 'program' | 'downloads'
) => {
	const { ubEventId, contentId } = event;

	const currentDomain = getDomainFromLocation();
	const environmentDomainPrefix = getEnvironmentDomainPrefix();

	if (currentDomain === 'uk') {
		const domain = `https://${environmentDomainPrefix}cmed.org.uk/`;
		const sectionString = section ? `?section=fomf-${section}` : '';
		const result = `${domain}ub-event/${ubEventId}${sectionString}`;

		return result;
	} else {
		const domain = `https://${environmentDomainPrefix}fomf.${currentDomain}/`;
		const sectionString = section ? `#${section}` : '';
		const result = `${domain}content/${contentId}${sectionString}`;

		return result;
	}
};

export const ukOnDemandEvents: Record<string, string> = {
	'6065': 'https://www.cmed.org.uk/gp-update-refresher-ondemand-062022#fomf-ondemand',
	'6455': 'https://www.cmed.org.uk/gp-update-refresher-ondemand-012023#fomf-ondemand',
	'6623': 'https://www.cmed.org.uk/gp-update-refresher-ondemand-062023#fomf-ondemand',
	'6959': 'https://www.cmed.org.uk/best-gp-update-refresher-ondemand#fomf-ondemand',
	'6884': 'https://www.cmed.org.uk/gp-update-refresher-ondemand-012024#fomf-ondemand'
};

export const getStreamRedirectUrl = (
	event: Pick<Types.EventR, 'ubEventId' | 'locale'> & {
		sponsorCompanyId?: string | null;
	},
	page: 'livestream' | 'on-demand' | 'exhibition',
	currentDomain: string
) => {
	const { ubEventId, locale: eventLocale, sponsorCompanyId } = event;

	// Here we have workaround for UK on-demand events
	// We don't want to create a proper solution because such events are 6 months long and only one exists at a time
	const ukOnDemandEventLink = ubEventId && ukOnDemandEvents[ubEventId];
	if (ukOnDemandEventLink) {
		return ukOnDemandEventLink;
	}

	const locale = eventLocale ? eventLocale : 'de-DE';
	const localeParam = locale === 'de-DE' ? 'de' : locale;
	const environmentDomainPrefix = getEnvironmentDomainPrefix();

	const domain =
		currentDomain === 'uk'
			? `https://${environmentDomainPrefix}cmed.org.uk/`
			: `https://${environmentDomainPrefix}fomf.${currentDomain}/`;

	const streamPath = event.ubEventId === '5901' ? 'stream-webups' : 'stream';

	const path = `${domain}${streamPath}/api/event-link/redirect-ub-event-id`;
	const queryParams = new URLSearchParams({
		locale: localeParam,
		page
	});
	if (ubEventId) {
		queryParams.set('event_ub_id', ubEventId);
	}
	if (sponsorCompanyId) {
		queryParams.set('sponsor_company_id', sponsorCompanyId);
	}

	const result = new URL(`${path}?${queryParams}`);

	return result.toString();
};
