import { Fragments } from '../..';

const currentYear = new Date().getFullYear();

export const addonMock = {
	__typename: 'Content',
	id: '054cfda7-e2a4-402b-a6af-7d097b5e16ae',
	brand: 'SYROT',
	endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
	externalId: '6450',
	contentId: '8097',
	contentType: 'ADDON_COURSE',
	sourceUrl: null,
	startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
	willBeBroadcasted: false,
	willBeOnDemand: true,
	isFree: false,
	isAvailableOnHomepage: null,
	participationOption: null,
	capacityStatus: null,
	locale: 'de-DE',
	description: null,
	descriptionTitle: null,
	title: null,
	videos: [],
	city: {
		'de-DE': 'Livestream',
		'de-AT': 'Livestream',
		'de-CH': 'Livestream',
		'fr-CH': 'Livestream',
		'en-GB': 'Livestream',
		'en-AU': 'Livestream'
	},
	contentProgress: {
		completed: true,
		contentId: '8105',
		time: 51.242328808102855,
		duration: 4101.481703019857,
		trackedDateTime: null,
		__typename: 'ContentProgress'
	},
	contentUnits: [
		{
			id: '3c68381b-e5a7-49b0-98aa-bbfa19aeba69',
			title: 'Unit 1',
			order: 2,
			__typename: 'ContentUnit'
		},
		{
			id: '49d6f668-7cb4-4126-9cc3-7cfa02615c25',
			title: 'Unit 2',
			order: 3,
			__typename: 'ContentUnit'
		},
		{
			id: '579af491-9303-49a8-a15c-cfa50e34d1be',
			title: 'Unit 3',
			order: 4,
			__typename: 'ContentUnit'
		}
	],
	membershipSettings: {
		availableForMembers: true,
		__typename: 'MembershipSettings'
	},
	detailsPageSettings: null,
	agendaSettings: null,
	accreditationSettings: null,
	seoSettings: null,
	sponsorships: [],
	partnerships: [
		{
			id: '10998b10-72be-4c58-9ef9-0a2ff93e8445',
			partnershipType: 'COOPERATION_PARTNER',
			partner: {
				name: 'Livestream',
				logoUrl: null,
				__typename: 'Partner'
			},
			__typename: 'Partnership'
		}
	],
	benefits: [],
	targetGroups: [],
	product: {
		name: {
			'de-DE': 'Innere Medizin',
			'de-AT': 'Innere Medizin',
			'de-CH': 'Innere Medizin',
			'fr-CH': 'Médecine interne',
			'en-GB': 'Internal Medicine',
			'en-AU': 'Internal Medicine'
		},
		description: null,
		specialityIconUrl:
			'https://storage.googleapis.com/bk-mediathek-staging-eu/public/topic/icon/FOMF%20Icons%20v0.1/Innere%20Medizin.svg',
		code: 'IM',
		id: 'cb09e7b5-5fe1-4ba9-84af-6626327988a8',
		country: 'DEU',
		isScientificLeaderEnabled: null,
		descriptionTitle: null,
		targetGroupIds: [],
		benefitIds: [],
		__typename: 'Product',
		benefits: [],
		targetGroups: []
	},
	credits: [],
	speaker: null,
	topics: [
		{
			id: '082ec6a7-ea5d-4c49-bbf2-997a2535cd34',
			backgroundUrl: '',
			code: 'IM',
			specialityIconUrl:
				'https://storage.googleapis.com/bk-platform-public-eu/topic/icon/blue_icons_26012023/Innere%20Medizin.svg',
			level: 0,
			subtopicPriority: 1,
			abbreviation: {
				'de-DE': 'Innere',
				'de-AT': 'Innere',
				'de-CH': 'Innere',
				'fr-CH': 'Méd. interne'
			},
			description: {
				'de-DE': 'Innere Medizin',
				'de-AT': 'Innere Medizin',
				'de-CH': 'Innere Medizin',
				'fr-CH': 'Médecine interne'
			},
			__typename: 'Topic',
			parents: []
		}
	],
	parent: null,
	replacementDate: null,
	replacementTitle: null,
	location:
		'Forum für medizinische Fortbildung - FomF GmbH (DE), Elisabethenstraße 1, Hofheim, 65719',
	headerUrl: null,
	dominantColor: null,
	schedule: [
		{
			id: 'e9d4e704-1fb8-4c06-8c88-aad04ca6973e',
			brand: 'SYROT',
			completed: false,
			time: 10,
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-5',
			contentId: '8101',
			contentType: 'LECTURE',
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture 1',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: false,
				contentId: '8105',
				time: 54.57146235428979,
				duration: 3082.225350541542,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: `${currentYear}-04-13`,
			replacementTitle: 'Lecture 1 Rep',
			contentUnit: {
				id: '3c68381b-e5a7-49b0-98aa-bbfa19aeba69',
				title: 'Unit 1',
				order: 2,
				__typename: 'ContentUnit'
			}
		},
		{
			id: '4a2976a8-d0d0-4fad-85d9-23261bd72c02',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-6',
			contentId: '8102',
			completed: false,
			time: 20,
			contentType: 'LECTURE',
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture 2',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: true,
				contentId: '8105',
				time: 65.69354091392535,
				duration: 4554.471740888504,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '3c68381b-e5a7-49b0-98aa-bbfa19aeba69',
				title: 'Unit 1',
				order: 2,
				__typename: 'ContentUnit'
			}
		},
		{
			id: '1796ff72-3c01-461b-b11e-bf2a4597355b',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-7',
			contentId: '8103',
			contentType: 'LECTURE',
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			completed: false,
			time: 15,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture 3',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: true,
				contentId: '8105',
				time: 25.712601232282218,
				duration: 7780.4757192018415,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '3c68381b-e5a7-49b0-98aa-bbfa19aeba69',
				title: 'Unit 1',
				order: 2,
				__typename: 'ContentUnit'
			}
		},
		{
			id: 'fe656d68-7c3f-4c2f-9f7d-dd25f512e5d3',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-8',
			contentId: '8104',
			contentType: 'LECTURE',
			completed: false,
			time: 0,
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture A',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: false,
				contentId: '8105',
				time: 71.33700249502348,
				duration: 4851.3896204828725,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '49d6f668-7cb4-4126-9cc3-7cfa02615c25',
				title: 'Unit 2',
				order: 3,
				__typename: 'ContentUnit'
			}
		},
		{
			id: 'ca269de8-8253-461d-80fb-504f29d7d069',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-9',
			contentId: '8105',
			contentType: 'LECTURE',
			completed: false,
			time: 0,
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture B',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: false,
				contentId: '8105',
				time: 68.6626353047688,
				duration: 2185.859928970545,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '49d6f668-7cb4-4126-9cc3-7cfa02615c25',
				title: 'Unit 2',
				order: 3,
				__typename: 'ContentUnit'
			}
		},
		{
			id: '4ae08db4-aec2-4efa-8c84-0135dcc70788',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-10',
			contentId: '8106',
			contentType: 'LECTURE',
			completed: false,
			time: 0,
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture C',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: true,
				contentId: '8105',
				time: 99.46747802567795,
				duration: 244.40610293177787,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '579af491-9303-49a8-a15c-cfa50e34d1be',
				title: 'Unit 3',
				order: 4,
				__typename: 'ContentUnit'
			}
		},
		{
			id: 'a40838a1-aa08-4af9-8849-8215ac15098c',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-11',
			contentId: '8107',
			contentType: 'LECTURE',
			completed: false,
			time: 0,
			sourceUrl: 'https://vimeo.com/502515997/aeeacb111f',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'Lecture D',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: true,
				contentId: '8105',
				time: 30.64917214073457,
				duration: 92.16983490128177,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '579af491-9303-49a8-a15c-cfa50e34d1be',
				title: 'Unit 3',
				order: 4,
				__typename: 'ContentUnit'
			}
		},
		{
			id: '78ce0738-467a-41ad-8532-d80dde9a7158',
			brand: 'SYROT',
			endDateTime: `${currentYear}-12-31T22:59:00.000Z`,
			externalId: '6450-12',
			contentId: '8108',
			contentType: 'PDF',
			sourceUrl:
				'https://storage.googleapis.com/bk-mediathek-staging-eu/public/Save%20The%20Date%202023.pdf',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			willBeBroadcasted: null,
			willBeOnDemand: true,
			isFree: false,
			isAvailableOnHomepage: true,
			participationOption: null,
			capacityStatus: null,
			locale: 'de-DE',
			description: null,
			descriptionTitle: null,
			title: 'PDF 1',
			city: {
				'de-DE': 'Livestream',
				'de-AT': 'Livestream',
				'de-CH': 'Livestream',
				'fr-CH': 'Livestream',
				'en-GB': 'Livestream',
				'en-AU': 'Livestream'
			},
			contentProgress: {
				completed: true,
				contentId: '8105',
				time: 71.47301260776932,
				duration: 7912.563235606482,
				trackedDateTime: null,
				__typename: 'ContentProgress'
			},
			contentUnits: [],
			membershipSettings: {
				availableForMembers: true,
				__typename: 'MembershipSettings'
			},
			detailsPageSettings: null,
			agendaSettings: null,
			accreditationSettings: null,
			seoSettings: null,
			sponsorships: [],
			partnerships: [],
			benefits: [],
			targetGroups: [],
			product: null,
			credits: [],
			speaker: null,
			topics: [],
			parent: {
				locale: 'de-DE',
				isFree: false,
				__typename: 'Content'
			},
			__typename: 'Content',
			replacementDate: null,
			replacementTitle: null,
			contentUnit: {
				id: '3c68381b-e5a7-49b0-98aa-bbfa19aeba69',
				title: 'Unit 1',
				order: 2,
				__typename: 'ContentUnit'
			}
		}
	],
	speakers: [],
	scientificLeaders: [],
	programSchedules: [
		{
			id: '0a13f5c6-4c6d-4412-a733-79411e29baf5',
			title: 'Unit 1',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 1,
			scheduleType: 'TOPIC_BLOCK',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: '9d418956-e3b8-4db9-8f3f-e5edeae79ca1',
			title: 'Unit 2',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 1,
			scheduleType: 'TOPIC_BLOCK',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'c3aa87aa-17fe-4d54-afcc-e8a5cdfc7326',
			title: 'Unit 3',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 1,
			scheduleType: 'TOPIC_BLOCK',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: '15c1aea6-8b9d-4283-8d0f-62e1e8c26003',
			title: 'Lecture 1',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: '78519e23-e023-4a64-be85-afd945d4783b',
			title: 'Lecture 2',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'af51eaa1-baed-4e7a-b46f-937720ef703b',
			title: 'Lecture 3',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'a6a1f632-80eb-411d-80ff-e80f27a3c299',
			title: 'Lecture A',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'b2efc2dc-c31c-407a-93cf-c9b688a32b70',
			title: 'Lecture B',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'cc888b90-4f37-4784-a196-ab117be42e2c',
			title: 'Lecture C',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		},
		{
			id: 'e2dbc05d-47c4-4475-82ea-6b5746bea38e',
			title: 'Lecture D',
			startDateTime: `${currentYear}-04-01T05:00:00.000Z`,
			endDateTime: `${currentYear}-12-30T23:00:00.000Z`,
			level: 2,
			scheduleType: 'LECTURE',
			speakers: [],
			__typename: 'ProgramSchedule'
		}
	],
	attachments: [],
	eventCost: null
} as unknown as Fragments.ContentWithProgramSchedulesFieldsFragment;
